<template>
  <BasicBlock :mobileContainer="true">
    <template v-slot:title>{{ title }}</template>

    <template v-slot:default>
      <HorizontalMenu v-if="!hideMenuOnMobile || (hideMenuOnMobile && !$device.isMobile)" class="grid-menu" :items="menu_items"/>

      <HomeGridItems :lazy="lazy" :items="items"/>
    </template>
  </BasicBlock>
</template>

<script>
import HorizontalMenu from "@/components/molecules/HorizontalMenu.vue";
import HomeGridItems from "@/components/molecules/HomeGridItems.vue";
import BasicBlock from "@/components/molecules/BasicBlock.vue";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => [],
    },
    hideMenuOnMobile: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    },
    menu_items: {
      type: Array,
      default: () => [],
    }
  },
  components: {BasicBlock, HorizontalMenu, HomeGridItems},
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";

.grid-menu {
  @include smd {
    position: absolute;
    right: 0;
    top: 8px;
  }

  &:deep(a) {
    @include smd {
      font-size: 12px;
      text-transform: initial;
    }
  }
}
</style>
